// @ts-check

const SUPER_SHORT_TITLE = 'TCSB';
const TITLE = `Trap y Corridos Soundboard (${SUPER_SHORT_TITLE})`;
const SHORT_TITLE = 'Trap Corridos Board';
const DESCRIPTION =
  `${TITLE} te permite escuchar y compartir tus frases favoritas de artistas de Trap y Corridos. ${TITLE} allows you to play and share your favorite catchphrases and ad-libs from Latin Trap & Corrido artists.`;
const SHORT_DESCRIPTION = 'Escucha y comparte tus frases favoritas de artistas de Trap y Corridos.'
const SITE_URL = 'https://trapcorridosboard.com';
const SCREENSHOT_FILE_NAME = 'screenshot.png';
const width = 1080;
const height = 567;
const tagsArr = [
  `<meta property="og:title" content="${TITLE}" />`,
  `<meta property="og:site_name" content="${TITLE}" />`,
  `<meta property="og:description" content="${DESCRIPTION}" />`,
  `<meta property="og:type" content="website" />`,
  `<meta property="og:image" content="${SITE_URL}/static/media/${SCREENSHOT_FILE_NAME}" />`,
  `<meta property="og:image:alt" content="${DESCRIPTION}" />`,
  `<meta property="og:image:width" content="${width}" />`,
  `<meta property="og:image:height" content="${height}" />`,
  `<meta property="og:image:type" content="image/png" />`,
  `<meta property="og:url" content="${SITE_URL}" />`,
  `<meta property="og:locale" content="es_ES" />`,
  `<meta property="og:locale:alternate" content="en_US" />`,
  `<meta name="google-site-verification" content="hDjR3fUb7x6qKm6VxotAWdXc0rbz1rFw07nG3kTyRp8" />`,
];
const TAGS = tagsArr.join('');

const VERSION = '1.0.7';

const BACKGROUND_COLOR = '#212121';

const PACKAGE_NAME = 'com.christianmemije.trapcorridossoundboard';

const CONTACT_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLScd0P0Zh7Td4RsY5svwba3Ve2d17x5kNcPTopsU1qrIPdClhA/viewform?usp=sf_link';

const REQUEST_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSeXO4SbA6ce2_pR8I-o_0gkrAer799sQSbH3lmdVH5GF4VqhA/viewform';

const PRIVACY_POLICY_FILE_NAME = 'privacy.html';
const CREDITS_FILE_NAME = 'credits.json';

const IG_URL = 'https://instagram.com/trapcorridosboard';

module.exports = {
  TITLE,
  SHORT_TITLE,
  SUPER_SHORT_TITLE,
  DESCRIPTION,
  TAGS,
  SCREENSHOT_FILE_NAME,
  VERSION,
  BACKGROUND_COLOR,
  PACKAGE_NAME,
  SITE_URL,
  CONTACT_FORM_URL,
  REQUEST_FORM_URL,
  PRIVACY_POLICY_FILE_NAME,
  CREDITS_FILE_NAME,
  IG_URL,
};
