import React, { memo } from 'react';
import { Paragraph, Caption, IconButton } from 'react-native-paper';
import { IAdLib } from '../utils/models';
import {
  StyleSheet,
  View,
  TouchableWithoutFeedback,
} from 'react-native';
import { SHARE, COPY_LINK } from '../utils/strings';
import { useTheme } from 'react-native-paper';
import { UNIT_MULTIPLE, ACCENT_COLOR } from '../utils/theme';

export const Pad = memo(
  PadComponent,
  (prev: AdLibProps, next: AdLibProps): boolean => {
    return (
      prev.pad.path === next.pad.path &&
      prev.isPlaying === next.isPlaying &&
      prev.canShare === next.canShare
    );
  },
);
function PadComponent({
  pad,
  onShare,
  onPlay,
  isPlaying,
  canShare,
}: AdLibProps) {
  const { colors } = useTheme();

  return (
    <TouchableWithoutFeedback onPress={() => onPlay()} style={styles.container}>
      <View
        style={[
          styles.padWrapper,
          { backgroundColor: isPlaying ? colors.background : colors.text },
        ]}
      >
        <View
          style={[
            styles.pad,
            { backgroundColor: isPlaying ? ACCENT_COLOR : colors.text },
          ]}
        >
          <View style={styles.left}>
            <Paragraph style={{ color: colors.surface }} numberOfLines={3}>
              {pad.name}
            </Paragraph>
            <Caption style={{ color: colors.surface }} numberOfLines={2}>
              {pad.artist}
            </Caption>
          </View>
          <View style={styles.right}>
            <IconButton
              icon={canShare ? 'share-variant' : 'link'}
              accessibilityLabel={canShare ? SHARE : COPY_LINK}
              color={colors.background}
              onPress={() => onShare()}
              style={styles.button}
            />
          </View>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

interface AdLibProps {
  pad: IPad;
  isPlaying: boolean;
  onPlay: () => void;
  canShare: boolean;
  onShare: () => void;
}

export interface IPad extends IAdLib {
  artist: string;
  genre: string;
  path: string;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  padWrapper: { padding: UNIT_MULTIPLE / 8, flex: 1 },
  pad: { flex: 1, padding: 0, flexDirection: 'row' },
  left: {
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
  },
  right: { alignSelf: 'flex-end' },
  button: { margin: 0 },
});
