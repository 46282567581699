export interface IGenre {
    name: string;
    artists: IArtist[];
}

export interface IArtist {
    name: string;
    adLibs: IAdLib[];
}

export interface IAdLib {
    name: string;
    url: string;
    start: number;
    end: number;
}

export enum EGenreMode {
    'Trap' = 'Trap',
    'Corridos' = 'Corridos',
    'TrapYCorridos' = 'Trap y Corridos',
}