export const AUDIO_MAP: any = {
  AldoTrujilloNotodoescomolopintan: require('./assets/audio/AldoTrujilloNotodoescomolopintan.mp3'),
  AlemanUnminutito: require('./assets/audio/AlemanUnminutito.mp3'),
  AnuelAABailedeldinero: require('./assets/audio/AnuelAABailedeldinero.mp3'),
  AnuelAABrrr: require('./assets/audio/AnuelAABrrr.mp3'),
  AnuelAARealhastalamuerte: require('./assets/audio/AnuelAARealhastalamuerte.mp3'),
  AnuelAAuuuuyyyyy: require('./assets/audio/AnuelAAuuuuyyyyy.mp3'),
  BadBunnyA200millasenunjetski: require('./assets/audio/BadBunnyA200millasenunjetski.mp3'),
  BadBunnyBadBunnyBABBBY: require('./assets/audio/BadBunnyBadBunnyBABBBY.mp3'),
  BadBunnyPasamelahookah: require('./assets/audio/BadBunnyPasamelahookah.mp3'),
  BigSotoEsVenezularealTRAMPA: require('./assets/audio/BigSotoEsVenezularealTRAMPA.mp3'),
  BryantMyersBRYANTMYERS: require('./assets/audio/BryantMyersBRYANTMYERS.mp3'),
  BryantMyersMEB: require('./assets/audio/BryantMyersMEB.mp3'),
  CarinLeonPaquesepacomorugeelleon: require('./assets/audio/CarinLeonPaquesepacomorugeelleon.mp3'),
  CazzuCazzuBaby: require('./assets/audio/CazzuCazzuBaby.mp3'),
  CazzuChapiChapi: require('./assets/audio/CazzuChapiChapi.mp3'),
  CazzuCumbiaNena: require('./assets/audio/CazzuCumbiaNena.mp3'),
  DukiDUKOO: require('./assets/audio/DukiDUKOO.mp3'),
  ElAlfaFUEGOFUEGOFIYAFIYA: require('./assets/audio/ElAlfaFUEGOFUEGOFIYAFIYA.mp3'),
  EstebanGabrielBumpingwiththefishscales: require('./assets/audio/EstebanGabrielBumpingwiththefishscales.mp3'),
  FaraonLoveShadyHibridoGang: require('./assets/audio/FaraonLoveShadyHibridoGang.mp3'),
  FaraonLoveShadyRAAAA: require('./assets/audio/FaraonLoveShadyRAAAA.mp3'),
  FuerzaRegidaOoootropedo: require('./assets/audio/FuerzaRegidaOoootropedo.mp3'),
  GrupoCodiciadoEylocos: require('./assets/audio/GrupoCodiciadoEylocos.mp3'),
  GrupoCodiciadoNoseenojen: require('./assets/audio/GrupoCodiciadoNoseenojen.mp3'),
  GrupoCodiciadoPuroCodiciadoLoco: require('./assets/audio/GrupoCodiciadoPuroCodiciadoLoco.mp3'),
  HerenciadePatronesClicaShii: require('./assets/audio/HerenciadePatronesClicaShii.mp3'),
  HerenciadePatronesEasypeazy: require('./assets/audio/HerenciadePatronesEasypeazy.mp3'),
  JonZEMBUSTE: require('./assets/audio/JonZEMBUSTE.mp3'),
  JonZJONZMAN: require('./assets/audio/JonZJONZMAN.mp3'),
  JonZJoseFeliciano: require('./assets/audio/JonZJoseFeliciano.mp3'),
  JonZLOCOHUMILDEYREAL: require('./assets/audio/JonZLOCOHUMILDEYREAL.mp3'),
  JonZSUPA: require('./assets/audio/JonZSUPA.mp3'),
  JonZTirosPalDiablo: require('./assets/audio/JonZTirosPalDiablo.mp3'),
  JonZViajosinver: require('./assets/audio/JonZViajosinver.mp3'),
  JonZYiiYiiYii: require('./assets/audio/JonZYiiYiiYii.mp3'),
  JuailloDiazAquiandamosynonosvamos: require('./assets/audio/JuailloDiazAquiandamosynonosvamos.mp3'),
  JuailloDiazHellalitmyboy: require('./assets/audio/JuailloDiazHellalitmyboy.mp3'),
  JuniorHQuesigalamatadando: require('./assets/audio/JuniorHQuesigalamatadando.mp3'),
  MykeTowersEasyMoneyBaby: require('./assets/audio/MykeTowersEasyMoneyBaby.mp3'),
  MykeTowersYoungKingz: require('./assets/audio/MykeTowersYoungKingz.mp3'),
  NatanaelCanoCorridostumbados: require('./assets/audio/NatanaelCanoCorridostumbados.mp3'),
  NatanaelCanoEaaa: require('./assets/audio/NatanaelCanoEaaa.mp3'),
  NatanaelCanoLilBii: require('./assets/audio/NatanaelCanoLilBii.mp3'),
  NatanaelCanoNATA: require('./assets/audio/NatanaelCanoNATA.mp3'),
  T3rElementoGanggangfoo: require('./assets/audio/T3rElementoGanggangfoo.mp3'),
};
