import React, { useState, useEffect, memo } from 'react';
import { StyleSheet, TextInput } from 'react-native';
import {
  Appbar,
  useTheme,
  Searchbar,
  IconButton,
  Menu,
} from 'react-native-paper';
import { TITLE, BACKGROUND_COLOR } from '../utils/constants';
import {
  EXIT_SEARCH,
  ENTER_SEARCH,
  CLEAR_SEARCH,
  SEARCH,
} from '../utils/strings';
import { EGenreMode } from '../utils/models';

export const Header = memo(
  HeaderComponent,
  (prev: HeaderProps, next: HeaderProps): boolean => {
    return (
      prev.inSearchMode === next.inSearchMode &&
      prev.searchTerm === next.searchTerm &&
      prev.genreMode === next.genreMode
    );
  },
);

export function HeaderComponent({
  inSearchMode,
  onSetSearchMode,
  searchTerm,
  onSetSearchTerm,
  genreMode,
  onSetGenreMode,
}: HeaderProps) {
  const { colors } = useTheme();

  const [searchBarRef, setSearchBarRef] = useState<TextInput>();
  const [filterMenuIsVisible, setFilterMenuIsVisible] = useState(false);

  useEffect(() => {
    if (inSearchMode && !!searchBarRef) {
      setTimeout(() => {
        searchBarRef.focus();
      }, 100);
    }
  }, [inSearchMode, searchBarRef]);

  return (
    <Appbar.Header style={styles.appBar}>
      {inSearchMode ? (
        <>
          <Appbar.Action
            icon="arrow-left"
            accessibilityLabel={EXIT_SEARCH}
            color={colors.text}
            onPress={() => {
              onSetSearchMode(false);
            }}
          />
          <Searchbar
            style={styles.searchBar}
            clearAccessibilityLabel={CLEAR_SEARCH}
            searchAccessibilityLabel={SEARCH}
            value={searchTerm}
            onChangeText={(newSearchTerm: string) =>
              onSetSearchTerm(newSearchTerm)
            }
            ref={(input: TextInput) => {
              setSearchBarRef(input);
            }}
          />
        </>
      ) : (
        <>
          <Appbar.Content title={TITLE} />
          <Appbar.Action
            icon="magnify"
            accessibilityLabel={ENTER_SEARCH}
            color={colors.text}
            onPress={() => onSetSearchMode(true)}
          />
        </>
      )}
      <Menu
        visible={filterMenuIsVisible}
        onDismiss={() => setFilterMenuIsVisible(false)}
        anchor={
          <IconButton
            onPress={() => setFilterMenuIsVisible(true)}
            icon="filter-variant"
          />
        }
      >
        {[EGenreMode.TrapYCorridos, EGenreMode.Trap, EGenreMode.Corridos].map(
          (genre: EGenreMode) => (
            <Menu.Item
              key={genre}
              title={genre}
              titleStyle={{
                color: genreMode === genre ? colors.primary : colors.text,
              }}
              onPress={() => {
                onSetGenreMode(genre);
                setFilterMenuIsVisible(false);
              }}
            />
          ),
        )}
      </Menu>
    </Appbar.Header>
  );
}

interface HeaderProps {
  inSearchMode: boolean;
  onSetSearchMode: (searchMode: boolean) => void;
  searchTerm: string;
  onSetSearchTerm: (term: string) => void;
  genreMode: EGenreMode;
  onSetGenreMode: (genreMode: EGenreMode) => void;
}

const styles = StyleSheet.create({
  appBar: { backgroundColor: BACKGROUND_COLOR },
  searchBar: { flex: 1 },
});
