import { configureFonts, DarkTheme } from 'react-native-paper';
import { Bungee_400Regular } from '@expo-google-fonts/bungee';

const fontConfig = {
  regular: {
    fontFamily: 'Bungee_400Regular',
    fontWeight: 'normal' as 'normal',
  },
  medium: {
    fontFamily: 'Bungee_400Regular',
    fontWeight: 'normal' as 'normal',
  },
  light: {
    fontFamily: 'Bungee_400Regular',
    fontWeight: 'normal' as 'normal',
  },
  thin: {
    fontFamily: 'Bungee_400Regular',
    fontWeight: 'normal' as 'normal',
  },
};

export const ACCENT_COLOR: string = '#ffc107';
export const APP_THEME = {
  ...DarkTheme,
  dark: true,
  colors: {
    ...DarkTheme.colors,
    primary: ACCENT_COLOR,
  },
  fonts: configureFonts({
    ios: fontConfig,
    android: fontConfig,
    macos: fontConfig,
    windows: fontConfig,
    web: fontConfig,
    default: fontConfig,
  }),
};

export const APP_FONTS = {
  Bungee_400Regular,
};

export const UNIT_MULTIPLE: number = 16;
