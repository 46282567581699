import { IGenre, IArtist, IAdLib, EGenreMode } from './models';
import { IPad } from '../components/Pad';
import jsonData from '../../assets/data.json';

function getPads(): IPad[] {
  const pads: IPad[] = [];
  const genres: IGenre[] = jsonData;
  genres.forEach((genre: IGenre) =>
    genre.artists.forEach((artist: IArtist) =>
      artist.adLibs.forEach((adLib: IAdLib) =>
        pads.push({
          ...adLib,
          artist: artist.name,
          genre: genre.name,
          path: `${artist.name}${adLib.name}`.replace(/\W/g, ''),
        }),
      ),
    ),
  );
  return pads;
}

export const allPads: IPad[] = getPads();
export const trapPads: IPad[] = allPads.filter(
  (pad: IPad) => pad.genre === EGenreMode.Trap,
);
export const corridoPads: IPad[] = allPads.filter(
  (pad: IPad) => pad.genre === EGenreMode.Corridos,
);
